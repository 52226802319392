export const listToObjectList=(dataSource)=>{
    //二维数组转为一维对象数组
    const changeData=[]
    for(let i=0;i<dataSource.length;i++)
    {
        const data={
            'term':dataSource[i][1],
            'courseName':dataSource[i][4],
            'credit':dataSource[i][6],
            'courseScore':dataSource[i][10],
            'gradePoint':dataSource[i][13],
        }
        changeData.push(data)
    }
    return changeData
}
export const calculateAll=(data)=>{
    //传入格式为二维数组,计算平均绩点
    let creditCount=0;
    let gradeCount=0;
    let gradePointCount=0;
    for(let i=0;i<data.length;i++)
    {
        creditCount+=(data[i]['credit']-0)
        gradeCount+=(data[i]['courseScore']-0)
        gradePointCount+=(data[i]['gradePoint']-0)*(data[i]['credit']-0)
    }
    return {
        creditCount: creditCount.toFixed(1),
        averGrade: gradePointCount===0?'0':gradeCount / creditCount.toFixed(3),
        averGradePoint:gradePointCount === 0 ? '0' : (gradePointCount / creditCount).toFixed(3)
    }
}
export const getTermMapAll=(data)=>{
    //data:二维数组
    //返回:数组
    let termList=[]
    let termMapList=[]
    for(let i=0;i<data.length;i++)
    {
        if(!termList.includes(data[i][1]))
            termList.push(data[i][1])
    }
    for(let i=0;i<termList.length;i++)
    {
        let object={}
        object['text']=termList[i].toString().match(/.{0,9}/g)[0]+(termList[i].toString().match(/.{0,9}/g)[1]==='-1-1'?'秋季学期':'春季学期')
        object['value']=termList[i]
        termMapList.push(object)
    }
    return termMapList
}
export const changeToGradePoint=(data)=>{
    //分数求得绩点
    for(let i=0;i<data.length;i++){
        let grade=data[i][10];
        if(grade===('优秀'))
        {
            data[i][13]=4.0
        }
        else if(grade==='良好')
        {
            data[i][13]=3.3
        }
        else if(grade==='中等')
        {
            data[i][13]=2.3
        }
        else if(grade==='及格')
        {
            data[i][13]=1.0
        }
        else if(grade==='不及格')
        {
            data[i][13]=0.0
        }else if(grade==='通过')
        {
            data[i][13]=3.3
        }else if(grade==='不通过')
        {
            data[i][13]=0.0
        }else if(grade-0>=90)
        {
            data[i][13]=4.0
        }
        else if(grade-0>=85)
        {
            data[i][13]=3.7
        }else if(grade-0>=82)
        {
            data[i][13]=3.3
        }else if(grade-0>=78)
        {
            data[i][13]=3.0
        }else if(grade-0>=75)
        {
            data[i][13]=2.7
        }else if(grade-0>=72)
        {
            data[i][13]=2.3
        }else if(grade-0>=68)
        {
            data[i][13]=2.0
        }else if(grade-0>=66)
        {
            data[i][13]=1.7
        }else if(grade-0>=64)
        {
            data[i][13]=1.5
        }else if(grade-0>=60)
        {
            data[i][13]=1.0
        }else
        {
            data[i][13]=0.0
        }
    }
    return data;
}