<template>
<el-dialog v-model="Tips"
           title="提示"
           width="80%"
           style="max-width: 450px">
 <p> 该网站开发纯属个人兴趣，与学校无关</p>
 <p>后台不会记录任何个人信息，但需要提交个人的账号</p>
 <p>以及MD5算法加密后的密码(同urp原理)进行爬虫登录urp</p>
 <p>交流邮箱:
 <a>
  rzou2022@163.com
 </a></p>
</el-dialog>
</template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Tips",
    props:['first'],
    data(){
        return{
            TipsShow:false
        }
    },
    methods:{

    },
 computed:{
     Tips(){
      return this.TipsShow||this.first
     }
 }
}
</script>

<style scoped>

</style>