<template>
    <div class="app">
<!--        传入全部学期及对象数组成绩-->
    <Current
        ref="Current"/>
        <LogIn  @changeLogIn="changeLogIn"
                @updateGrade="updateGrade"
                ref="LogIn"/>
    </div>
</template>

<script>
import Current from "@/components/Current.vue";
import LogIn from "@/components/LogIn.vue";
import {getTermMapAll, listToObjectList} from "@/utils/current";

export default {
  name: 'App',
  components: {
      LogIn,
      Current
  },
    methods:{
        getTermMapAll,
        changeLogIn(){
          this.$refs.Current.logInStatus=!this.$refs.Current.logInStatus
          this.$refs.LogIn.logInStatus=!this.$refs.LogIn.logInStatus
        },
        updateGrade(list){
            this.$refs.Current.gradeList=listToObjectList(list)
            const terms=getTermMapAll(list)
            this.$refs.Current.terms=terms
            this.$refs.Current.selectedTerms.push(terms[terms.length-1])
        }
    },
}
</script>

<style>
.app{
    /*background-image: url("./assets/background.jpg");*/
    /*background-size: contain;*/
    margin: auto;
    text-align: center;
    height: 95vh;
    max-width: 800px;
}
</style>
