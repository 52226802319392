<template>
    <el-container v-if="logInStatus">
        <Tips ref="tips"
              :first="true"/>
        <el-header class="head">
            <div style="margin-top: 5%">
            <el-text  style="font-size: 2rem">登录</el-text>
            </div>
        </el-header>
        <el-main class="main">
            <el-col :span="16" style="margin: auto">
<el-form >
    <el-form-item  label="" style="height: 60px">
        <el-input v-model="j_username" style="height: 60px" placeholder="账号(同urp)"></el-input>
    </el-form-item>
    <el-form-item  label="" style="height: 60px">
        <el-input type="password" v-model="j_password" style="height: 60px" placeholder="密码">
        </el-input>
    </el-form-item>
      <el-form-item>
           <el-input v-model="j_captcha" style="height: 60px" placeholder="验证码"></el-input>
     </el-form-item >
  <div @click="refreshCaptcha">
         <el-image :src="url" style="height: 60px;margin-bottom: 10px" /></div>
    <el-col style="margin: auto;height: 60px">
        <el-button @click="LogInEvent"  style="width:100%;height: 60px;font-size: 1.3rem">登录</el-button>
    </el-col>
</el-form>
            </el-col>
        </el-main>
    </el-container>
</template>

<script>
import {allTermScores, j_spring_security_check} from "@/axios/api";
import md5 from 'js-md5';
// import {testData} from "@/axios/data";
import {baseURLs} from "@/axios/httpConfig";
import {changeToGradePoint} from "@/utils/current";
import Tips from "@/components/Tips.vue";
export default {
    name: "logIn",
    components: {Tips},
    props:['updateGrade','changeLogIn'],
    data(){
        return{
            logInStatus:true,
            j_username:'',
            j_password:'',
            j_captcha:'',
            url:baseURLs+"/img/captcha.jpg",
            // url:"http://localhost:9000/img/captcha.jpg",
            // url:'require('../assets/captcha.jpg')',
            fit:'contain'
        }
    },
    methods:{
        LogInEvent(){
            const that=this
            this.$refs.tips.TipsShow=false
            // that.$emit('updateGrade',changeToGradePoint(testData()))
            // that.$emit('changeLogIn')
            // that.refreshCaptcha()
            j_spring_security_check(this.j_username,md5(this.j_password),this.j_captcha)
                .then(response=>{return response.message==='success!'})
                .then(response=>{return response?allTermScores():false})
                .then(response=>{if(response===false) return;that.$emit('changeLogIn');that.$emit('updateGrade',changeToGradePoint(response.data)) })
                .finally(()=>{that.refreshCaptcha()})
        },
        refreshCaptcha(){
            // console.log(Math.random()*10)
            this.url=baseURLs+"/img/captcha.jpg"+'?'+Math.ceil(Math.random()*10)
        }
    },
}
</script>

<style scoped>
.head{
    height: 10vh;
}
.main{
    height: 70vh;
 text-align: center;
}

</style>