import {http} from "@/axios/httpConfig";
function j_spring_security_check(j_username,j_password,j_captcha) {
   return  http.get('/j_spring_security_check',{'j_username':j_username,'j_password':j_password,'j_captcha':j_captcha})
}
function allTermScores() {
    return http.get('/student/integratedQuery/scoreQuery/allTermScores/index','')
}
export {
  j_spring_security_check,
  allTermScores
}