import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app= createApp(App)
app.use(ElementPlus).mount('#app')
// 使用el-cion
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}