<template>
    <el-dialog v-model="ChoicesShow" title="筛选" width="60%">
        <el-row justify="center">
            <el-checkbox-group v-model="selectedTerms">
            <el-col v-for="term in terms" :key="term" style="margin: auto">
                <el-checkbox :label="term.text"></el-checkbox>
            </el-col>
            </el-checkbox-group>
            <el-col style="margin: auto">
                <el-button @click="selectTerms()">确定</el-button>
            </el-col>
        </el-row>
    </el-dialog>
</template>
<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "Choices",
    props:['terms','updateSelectedTerms'],
    data(){
        return{
            ChoicesShow:false,
            selectedTerms:[],
        }
    },
    methods:{
        selectTerms(){
            this.ChoicesShow=false
            let termTo=[]
            for(let i=0;i<this.selectedTerms.length;i++)
                for(let j=0;j<this.terms.length;j++)
                    if(this.selectedTerms[i]===this.terms[j].text)
                        termTo.push(this.terms[j])
            this.$emit('updateSelectedTerms',termTo)
        }
    }
}
</script>

<style scoped>

</style>