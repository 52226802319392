<template>
    <div v-if="logInStatus">
        <el-container >
            <el-header height="5vh">
                <el-row class="head">
                <el-col :span="6" >
                    <el-text  style="font-size: 1.5rem">历史成绩</el-text>
                </el-col>
                <el-col :span="4" :offset="13" class="icon">
                  <QuestionFilled style="width: 1em; height: 1em;margin-right: 10%" @click="changeTips"/>
                <Search style="width: 1em; height: 1em" @click="changeChoices"/>
                </el-col>
                </el-row>
            </el-header>
            <el-main class="main" >
              <Tips ref="Tips"
                    :first="false"/>
              <Choices ref="Choices"
                       :terms="terms"
                       @updateSelectedTerms="updateSelectedTerms"/>
                <el-row class="scrollbar-demo-item" >
                    <el-col :span="12" >科目</el-col>
                    <el-col :span="4" >学分</el-col>
                    <el-col :span="4" >成绩</el-col>
                    <el-col :span="4" >绩点</el-col>
                </el-row>
                <div style="height: 70vh">
                    <el-scrollbar >
                        <el-row  v-for="item in selectedGradeList" :key="item" class="scrollbar-demo-item">
                            <el-col :span="12" >{{item.courseName}}</el-col>
                            <el-col :span="4" >{{item.credit}}</el-col>
                            <el-col :span="4" >{{item.courseScore}}</el-col>
                            <el-col :span="4" >{{item.gradePoint}}</el-col>
                        </el-row>
                    </el-scrollbar>
                </div>
            </el-main>
            <el-footer >
                <el-row class="bottom">
                    <el-col :span="10" style="margin: auto">
                        <el-text  style="font-size: 1.3rem">总学分:{{countList.creditCount}}</el-text>
                    </el-col>
                    <el-col :span="14" style="margin: auto">
                        <el-text  style="font-size: 1.3rem">综合绩点:{{countList.averGradePoint}}</el-text>
                    </el-col>
                </el-row>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
import {QuestionFilled, Search} from "@element-plus/icons-vue";
import Tips from "@/components/Tips.vue";
import Choices from "@/components/Choices.vue";
import {calculateAll} from "@/utils/current";
export default {
    //gradeList为全部成绩，terms为全部学期
    //selectedList为筛选学期的成绩 selectedTerms为筛选的学期
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Current',
    components: {Choices, Tips, QuestionFilled, Search},
    data(){
        return{
            terms:[],
            gradeList:[],
            logInStatus:false,
            selectedTerms:[],
        }
    },
    computed:{
        selectedGradeList(){
            let data=[]
            for(let i=0;i<this.selectedTerms.length;i++)
             for(let j=0;j<this.gradeList.length;j++) {
                 if (this.gradeList[j].term === this.selectedTerms[i].value)
                     data.push(this.gradeList[j])
             }
            return data
        },
        countList(){
          return  calculateAll(this.selectedGradeList)
        }
    },
    methods:{
        updateSelectedTerms(list){
            this.selectedTerms=list
        },
        changeTips(){
            this.$refs.Tips.TipsShow=true;
        },
        changeChoices(){
            this.$refs.Choices.ChoicesShow=true;
        },
    }
}
</script>

<style>
.head{
    margin-top: 2%;
}
.icon{
    font-size: 1.5rem;
    text-align: right;
    color: var(--el-color-primary);
}
.main{

}

.scrollbar-demo-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    margin: 10px;
    text-align: center;
    border-radius: 4px;
    background: var(--el-color-primary-light-9);
    color: var(--el-color-primary);
}
.bottom{
    text-align: center;
    margin-top: 3%;
}
</style>
